import { type PropsWithChildren, useEffect, useRef, useState } from 'react';

import { useLocation } from '@remix-run/react';

import styles from './styles.module.css';

import DodgeballLogo from '@images/logo-main.png';
import classNames from 'classnames';

export function InitialLoading({ children }: PropsWithChildren) {
  const { pathname } = useLocation();
  const [shown, setShown] = useState(!pathname.includes('/scoreboard/') && !pathname.includes('/scorebug/'));
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      setTimeout(() => {
        setShown(false);
      }, 800);
    }
  }, []);
  return (
    <>
      <div className={classNames(styles.initialLoad, { [styles.shown]: shown })}>
        <span className={styles.logoWrapper}>
          <span className={styles.icon} />
        </span>
      </div>
      {children}
    </>
  );
}
